<template>
    <div>
        <!-- Checkbox for farmSeed -->
        <b-form-checkbox
        v-model="farmSeed"
        @input="updateFarmSeed()"
        >
            <h4 class="subtitle-form">{{ $t("inputStock.details.farm_seed") }}</h4>
        </b-form-checkbox>
    </div>

</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { EventBus } from "@/eventBus.js";

export default {
    mixins: [DetailUpdateMixin],
    data() {
        return {
            farmSeed: false
        }
    },
    mounted(){
        // Reusing the "resetProductSelection" event to reset farmSeed
        EventBus.$on("resetProductSelection", async () => {
            this.farmSeed = false;
            await this.$store.dispatch(this.storeEditAction, {
                    farmSeed: this.farmSeed,
                });
        })

        if(this.currentEntry.farmSeed) this.farmSeed = this.currentEntry.farmSeed;
    },
    methods: {
        async updateFarmSeed(){
            // update the CurrentEditedEntry
            await this.$store.dispatch(this.storeEditAction, {
                    farmSeed: this.farmSeed,
                });
            // update the flag that is used to determine if we need the treatment dose (SeedTreatmentForm component)
            await this.$store.dispatch("inputStock/setFarmSeed", this.farmSeed);
        },
    }
}

</script>